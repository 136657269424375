import ApplicationController from './application_controller'
import { get } from '@rails/request.js'

export default class extends ApplicationController {
  static targets = ['form', 'submit']
  static values = {
    url: { type: String, default: '' },
  }

  search() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      get(`${this.urlValue}?q=${this.element.value}`, {
        responseKind: 'turbo-stream',
      })
    }, 200)
  }

  disableSubmit() {
    this.submitTargets.forEach((target) => (target.disabled = true))
  }

  enableSubmit() {
    this.submitTargets.forEach((target) => (target.disabled = false))
  }

  // TODO: Deprecate this in favor of requestSubmit()
  submit(e) {
    e.target.form.requestSubmit()
  }

  requestSubmit(e) {
    let form

    if (this.hasFormTarget) {
      form = this.formTarget
    } else {
      form = this.element
    }

    form.requestSubmit()
  }
}
